<template>
  <div>sub-category-list</div>
</template>

<script>
export default {

}
</script>

<style>

</style>
