import { get } from '@/utils/axios'

class Category {
  static async getList() {
    const res = await get('v1/category/list')
    return res
  }
}

export default Category
