<template>
  <div>
    <div v-if="!showEdit" class="container">
      <div class="header">
        <div class="title" style="font-size: 24px;">обзор аккаунта  <span style="color: #2878ff;"> -  {{name}}</span></div>
        <div style="display: flex;align-items: center;justify-content: center;">
          {{ sday }} <img style="width: 32px; height: 32px;margin-left: 20px;" src="./img/setting.png" /> <img style="width: 28px; height: 28px;margin-left: 20px;" src="./img/user.png" />
        </div>
      </div>

      <div class="count-wrapper">
        <div class="count-item">
          <div class="title">заказать<br/>товар</div>
          <div class="num">2152</div>
        </div>
        <div class="h-line"></div>
        <div class="count-item">
          <div class="title">Товары<br/>продаются</div>
          <div class="num">2376</div>
        </div>
        <div class="h-line"></div>
        <div class="count-item">
          <div class="title">сегодня<br/>распродажа</div>
          <div class="num">11,386.00 ₽</div>
        </div>
        <div class="h-line"></div>
        <div class="count-item">
          <div class="title">Баланс</div>
          <div class="num">90,497.51 ₽</div>
        </div>
        <div class="h-line"></div>
        <div class="count-item">
          <div class="title">снятие<br/>доступно</div>
          <div class="num">68,901.51 ₽</div>
        </div>
      </div>

      <div class="header">
        <div class="title" style="font-size: 24px;">продажи продуктов</div>
      </div>
      <el-table :data="list">
        <el-table-column prop="img" width="180" label="изображение">
          <template v-if="scope.row.img" slot-scope="scope">
            <img :src="require(`./${scope.row.img}`)" style="width: 100px; height: 100px"/>
          </template>
        </el-table-column>
        <el-table-column prop="data1" label="код продукта"></el-table-column>
        <el-table-column prop="data2" label="инвентарь"></el-table-column>
        <el-table-column prop="data3" width="150">
          <template slot="header">
            <div>изначальная</div>
            <div>цена</div>
          </template>
          <template slot-scope="scope">
            {{scope.row.data3 | num}} ₽
          </template>
        </el-table-column>
        <el-table-column prop="data4" label="СКИДКА"></el-table-column>
        <el-table-column prop="data5">
          <template slot="header">
            <div>ЦЕНА СО</div>
            <div>СКИДКОЙ</div>
          </template>
          <template slot-scope="scope">
            {{scope.row.data5 | num}} ₽
          </template>
        </el-table-column>
        <el-table-column prop="data6">
          <template slot="header">
            <div>накопленные</div>
            <div>продажи</div>
          </template>
          <template slot-scope="scope">
            {{scope.row.data6 | num}} ₽
          </template>
        </el-table-column>
        <el-table-column prop="data7">
          <template slot="header">
            <div>Сегодня</div>
            <div>продажи</div>
          </template>
          <template slot-scope="scope">
            {{scope.row.data7 | num}} ₽
          </template>
        </el-table-column>
        <el-table-column prop="data8">
          <template slot="header">
            <div>ежемесячные</div>
            <div>продажи</div>
          </template>
          <template slot-scope="scope">
            {{scope.row.data8 | num}} ₽
          </template>
        </el-table-column>
        <!-- <el-table-column width="150" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button @click.prevent="handleEdit(scope.row)" size="mini">编辑</el-button>
            <el-button
              size="mini"
              type="danger">删除</el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import Spu from '@/model/spu'
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  data() {
    return {
      imagePath: './',
      sday: '',
      spuId: null,
      list: [],
      list1: [{
        "data1": "4602509784792",
        "data2": "1649",
        "data3": "499",
        "data4": "-45%",
        "data5": "276",
        "data6": "10396920",
        "data7": "105",
        "data8": "28980",
        "img": "img/1.png"
      }, {
        "data1": "4609898605542",
        "data2": "2568",
        "data3": "259",
        "data4": "-25%",
        "data5": "195",
        "data6": "13174200",
        "data7": "225",
        "data8": "43875",
        "img": "img/2.png"
      }, {
        "data1": "4601565767892",
        "data2": "1124",
        "data3": "324",
        "data4": "-25%",
        "data5": "244",
        "data6": "11904760",
        "data7": "87",
        "data8": "21228",
        "img": "img/3.png"
      }, {
        "data1": "4606577654542",
        "data2": "1346",
        "data3": "207",
        "data4": "-25%",
        "data5": "156",
        "data6": "1925040",
        "data7": "128",
        "data8": "19968",
        "img": "img/4.png"
      }, {
        "data1": "4609885763442",
        "data2": "784",
        "data3": "415",
        "data4": "-31%",
        "data5": "287",
        "data6": "12475890",
        "data7": "146",
        "data8": "41902",
        "img": "img/5.png"
      }, {
        "data1": "4605563887932",
        "data2": "792",
        "data3": "139",
        "data4": "-30%",
        "data5": "97",
        "data6": "1451120",
        "data7": "120",
        "data8": "11640",
        "img": "img/6.png"
      }, {
        "data1": "4618765390872",
        "data2": "148",
        "data3": "233",
        "data4": "-25%",
        "data5": "175",
        "data6": "1060500",
        "data7": "56",
        "data8": "9800",
        "img": "img/7.png"
      }, {
        "data1": "4619876538762",
        "data2": "112",
        "data3": "250",
        "data4": "-22%",
        "data5": "195",
        "data6": "1443000",
        "data7": "21",
        "data8": "4095",
        "img": "img/8.png"
      }, {
        "data1": "4614527653982",
        "data2": "57",
        "data3": "649",
        "data4": "-41%",
        "data5": "386",
        "data6": "239320",
        "data7": "11",
        "data8": "4246",
        "img": "img/9.png"
      }, {
        "data1": "4618673658642",
        "data2": "34",
        "data3": "161",
        "data4": "-40%",
        "data5": "97",
        "data6": "623710",
        "data7": "5",
        "data8": "485",
        "img": "img/10.png"
      }, {
        "data1": "4619063876502",
        "data2": "157",
        "data3": "584",
        "data4": "-31%",
        "data5": "404",
        "data6": "1131200",
        "data7": "36",
        "data8": "14544",
        "img": "img/11.png"
      }, {
        "data1": "4618865339632",
        "data2": "258",
        "data3": "558",
        "data4": "-31%",
        "data5": "386",
        "data6": "1316260",
        "data7": "89",
        "data8": "34354",
        "img": "img/12.png"
      }, {
        "data1": "4610974438872",
        "data2": "313",
        "data3": "259",
        "data4": "-25%",
        "data5": "195",
        "data6": "2193750",
        "data7": "65",
        "data8": "12675",
        "img": "img/13.png"
      }, {
        "data1": "4619874433802",
        "data2": "27",
        "data3": "389",
        "data4": "-37%",
        "data5": "244",
        "data6": "373320",
        "data7": "9",
        "data8": "2196",
        "img": "img/14.png"
      }, {
        "data1": "4616633896532",
        "data2": "108",
        "data3": "337",
        "data4": "-25%",
        "data5": "254",
        "data6": "254000",
        "data7": "19",
        "data8": "4826",
        "img": "img/15.png"
      }, {
        "data1": "4619857308692",
        "data2": "85",
        "data3": "428",
        "data4": "-31%",
        "data5": "296",
        "data6": "1465200",
        "data7": "21",
        "data8": "6216",
        "img": "img/16.png"
      }, {
        "data1": "4628899643562",
        "data2": "48",
        "data3": "156",
        "data4": "-74%",
        "data5": "40",
        "data6": "2800",
        "data7": "11",
        "data8": "440",
        "img": "img/17.png"
      }, {
        "data1": "4628573640092",
        "data2": "100",
        "data3": "899",
        "data4": "-68%",
        "data5": "288",
        "data6": "14400",
        "data7": "19",
        "data8": "5472",
        "img": "img/18.png"
      }, {
        "data1": "4623366487592",
        "data2": "46",
        "data3": "488",
        "data4": "-49%",
        "data5": "254",
        "data6": "210820",
        "data7": "12",
        "data8": "3048",
        "img": "img/19.png"
      }, {
        "data1": "4628544399852",
        "data2": "8",
        "data3": "249",
        "data4": "-67%",
        "data5": "81",
        "data6": "24300",
        "data7": "1",
        "data8": "81",
        "img": "img/20.png"
      }],
      list2: [{
        "data1": "4633456543433",
        "data2": "106",
        "data3": "3818",
        "data4": "−56%",
        "data5": "1663",
        "data6": "13270740",
        "data7": "12",
        "data8": "19956",
        "img": "img/21.png"
      }, {
        "data1": "4638444378963",
        "data2": "2497",
        "data3": "273",
        "data4": "−36%",
        "data5": "174",
        "data6": "15766140",
        "data7": "146",
        "data8": "25404",
        "img": "img/22.png"
      }, {
        "data1": "4637736251273",
        "data2": "648",
        "data3": "289",
        "data4": "−38%",
        "data5": "179",
        "data6": "7389120",
        "data7": "109",
        "data8": "19511",
        "img": "img/23.png"
      }, {
        "data1": "4639006488793",
        "data2": "956",
        "data3": "344",
        "data4": "−44%",
        "data5": "193",
        "data6": "5058530",
        "data7": "98",
        "data8": "18914",
        "img": "img/24.png"
      }, {
        "data1": "4639875467873",
        "data2": "15",
        "data3": "535",
        "data4": "−52%",
        "data5": "258",
        "data6": "2368440",
        "data7": "2",
        "data8": "516",
        "img": "img/25.png"
      }, {
        "data1": "4639976438773",
        "data2": "80",
        "data3": "583",
        "data4": "−52%",
        "data5": "281",
        "data6": "3062900",
        "data7": "11",
        "data8": "3091",
        "img": "img/26.png"
      }, {
        "data1": "4638874379063",
        "data2": "509",
        "data3": "344",
        "data4": "−44%",
        "data5": "193",
        "data6": "5043090",
        "data7": "67",
        "data8": "12931",
        "img": "img/27.png"
      }, {
        "data1": "4638576489943",
        "data2": "381",
        "data3": "644",
        "data4": "−50%",
        "data5": "319",
        "data6": "2743400",
        "data7": "91",
        "data8": "29029",
        "img": "img/28.png"
      }, {
        "data1": "4637584657483",
        "data2": "96",
        "data3": "880",
        "data4": "−54%",
        "data5": "403",
        "data6": "1304511",
        "data7": "13",
        "data8": "5239",
        "img": "img/29.png"
      }, {
        "data1": "4636789865453",
        "data2": "16",
        "data3": "273",
        "data4": "−38%",
        "data5": "169",
        "data6": "508690",
        "data7": "1",
        "data8": "169",
        "img": "img/30.png"
      }, {
        "data1": "4639987656753",
        "data2": "30",
        "data3": "1200",
        "data4": "−55%",
        "data5": "540",
        "data6": "923400",
        "data7": "9",
        "data8": "4860",
        "img": "img/31.png"
      }, {
        "data1": "4639876545673",
        "data2": "251",
        "data3": "698",
        "data4": "−53%",
        "data5": "330",
        "data6": "1722600",
        "data7": "46",
        "data8": "15180",
        "img": "img/32.png"
      }, {
        "data1": "4630087656463",
        "data2": "309",
        "data3": "453",
        "data4": "−44%",
        "data5": "255",
        "data6": "8254350",
        "data7": "103",
        "data8": "26265",
        "img": "img/33.png"
      }, {
        "data1": "4631765343963",
        "data2": "261",
        "data3": "339",
        "data4": "−44%",
        "data5": "191",
        "data6": "2679730",
        "data7": "64",
        "data8": "12224",
        "img": "img/34.png"
      }, {
        "data1": "4630765498793",
        "data2": "215",
        "data3": "515",
        "data4": "−44%",
        "data5": "290",
        "data6": "4854600",
        "data7": "54",
        "data8": "15660",
        "img": "img/35.png"
      }, {
        "data1": "4632375689653",
        "data2": "85",
        "data3": "273",
        "data4": "−38%",
        "data5": "169",
        "data6": "2900040",
        "data7": "21",
        "data8": "3549",
        "img": "img/36.png"
      }, {
        "data1": "4631267878433",
        "data2": "233",
        "data3": "273",
        "data4": "−38%",
        "data5": "169",
        "data6": "1946880",
        "data7": "97",
        "data8": "16393",
        "img": "img/37.png"
      }, {
        "data1": "4638694876543",
        "data2": "851",
        "data3": "463",
        "data4": "−39%",
        "data5": "284",
        "data6": "25733240",
        "data7": "112",
        "data8": "31808",
        "img": "img/38.png"
      }, {
        "data1": "4638676437853",
        "data2": "451",
        "data3": "344",
        "data4": "−44%",
        "data5": "193",
        "data6": "5023790",
        "data7": "99",
        "data8": "19107",
        "img": "img/39.png"
      }, {
        "data1": "4637584975843",
        "data2": "16",
        "data3": "1200",
        "data4": "−55%",
        "data5": "540",
        "data6": "405000",
        "data7": "2",
        "data8": "1080",
        "img": "img/40.png"
      }, {
        "data1": "4637586953213",
        "data2": "6",
        "data3": "273",
        "data4": "−42%",
        "data5": "157",
        "data6": "1703450",
        "data7": "0",
        "data8": "0",
        "img": "img/41.png"
      }, {
        "data1": "4639066475893",
        "data2": "437",
        "data3": "371",
        "data4": "−43%",
        "data5": "213",
        "data6": "5674320",
        "data7": "106",
        "data8": "22578",
        "img": "img/42.png"
      }],
      list3: [{
        "data1": "4648594637584",
        "data2": "89",
        "data3": "936 ",
        "data4": "−68%",
        "data5": "297",
        "data6": "608850",
        "data7": "11",
        "data8": "3267",
        "img": "img/43.png"
      }, {
        "data1": "4641876598764",
        "data2": "106",
        "data3": "7290",
        "data4": "−72%",
        "data5": "2046",
        "data6": "9104700",
        "data7": "3",
        "data8": "6138",
        "img": "img/44.png"
      }, {
        "data1": "4640998765324",
        "data2": "35",
        "data3": "936",
        "data4": "−62%",
        "data5": "357",
        "data6": "7129290",
        "data7": "7",
        "data8": "2499",
        "img": "img/45.png"
      }, {
        "data1": "4640087645374",
        "data2": "36",
        "data3": "936",
        "data4": "−69%",
        "data5": "293",
        "data6": "17600510",
        "data7": "9",
        "data8": "2637",
        "img": "img/46.png"
      }, {
        "data1": "4640964738654",
        "data2": "13",
        "data3": "1248",
        "data4": "−75%",
        "data5": "313",
        "data6": "1295820",
        "data7": "8",
        "data8": "2504",
        "img": "img/47.png"
      }, {
        "data1": "4640088675634",
        "data2": "89",
        "data3": "624",
        "data4": "−89%",
        "data5": "66",
        "data6": "142560",
        "data7": "12",
        "data8": "792",
        "img": "img/48.png"
      }, {
        "data1": "4649856009864",
        "data2": "36",
        "data3": "3124",
        "data4": "−77%",
        "data5": "719",
        "data6": "5507540",
        "data7": "11",
        "data8": "7909",
        "img": "img/49.png"
      }, {
        "data1": "4640064378564",
        "data2": "19",
        "data3": "1874 ",
        "data4": "−72%",
        "data5": "517",
        "data6": "6524540",
        "data7": "8",
        "data8": "4136",
        "img": "img/50.png"
      }, {
        "data1": "4648967432364",
        "data2": "247",
        "data3": "936",
        "data4": "−69%",
        "data5": "292",
        "data6": "17563800",
        "data7": "86",
        "data8": "25112",
        "img": "img/51.png"
      }, {
        "data1": "4648756473524",
        "data2": "97",
        "data3": "1040",
        "data4": "−87%",
        "data5": "138",
        "data6": "389160",
        "data7": "23",
        "data8": "3174",
        "img": "img/52.png"
      }, {
        "data1": "4649867584764",
        "data2": "145",
        "data3": "1354",
        "data4": "−64%",
        "data5": "474",
        "data6": "2398440",
        "data7": "45",
        "data8": "21330",
        "img": "img/53.png"
      }, {
        "data1": "4640976858564",
        "data2": "221",
        "data3": "1666 ",
        "data4": "−59%",
        "data5": "689",
        "data6": "992160",
        "data7": "101",
        "data8": "69589",
        "img": "img/54.png"
      }, {
        "data1": "4648765897654",
        "data2": "45",
        "data3": "1040",
        "data4": "−70%",
        "data5": "313",
        "data6": "2397580",
        "data7": "12",
        "data8": "3756",
        "img": "img/55.png"
      }, {
        "data1": "4642365748674",
        "data2": "6",
        "data3": "1562 ",
        "data4": "−76%",
        "data5": "374",
        "data6": "1552100",
        "data7": "1",
        "data8": "374",
        "img": "img/56.png"
      }, {
        "data1": "4649876574544",
        "data2": "56",
        "data3": "2082",
        "data4": "−52%",
        "data5": "1003",
        "data6": "1795370",
        "data7": "21",
        "data8": "21063",
        "img": "img/57.png"
      }, {
        "data1": "4642386756454",
        "data2": "4",
        "data3": "2707",
        "data4": "−81%",
        "data5": "517",
        "data6": "1111550",
        "data7": "0",
        "data8": "0",
        "img": "img/58.png"
      }, {
        "data1": "4646547357654",
        "data2": "13",
        "data3": " 1874",
        "data4": "−67%",
        "data5": "625",
        "data6": "3162500",
        "data7": "3",
        "data8": "1875",
        "img": "img/59.png"
      }, {
        "data1": "4642312785794",
        "data2": "17",
        "data3": "936",
        "data4": "−70%",
        "data5": "278",
        "data6": "2315740",
        "data7": "1",
        "data8": "278",
        "img": "img/60.png"
      }, {
        "data1": "4645768799654",
        "data2": "17",
        "data3": "1248",
        "data4": "−66%",
        "data5": "422",
        "data6": "5325640",
        "data7": "7",
        "data8": "2954",
        "img": "img/61.png"
      }, {
        "data1": "4640643257654",
        "data2": "75",
        "data3": "832",
        "data4": "−67%",
        "data5": "278",
        "data6": "1381660",
        "data7": "11",
        "data8": "3058",
        "img": "img/62.png"
      }, {
        "data1": "4640967854974",
        "data2": "78",
        "data3": "728",
        "data4": "−73%",
        "data5": "195",
        "data6": "5183100",
        "data7": "22",
        "data8": "4290",
        "img": "img/63.png"
      }, {
        "data1": "4648859675904",
        "data2": "23",
        "data3": "519 ",
        "data4": "−57%",
        "data5": "224",
        "data6": "2457280",
        "data7": "9",
        "data8": "2016",
        "img": "img/64.png"
      }],
      list4: [{
        "data1": "4659987687675",
        "data2": "356",
        "data3": "5360",
        "data4": "−78%",
        "data5": "1189",
        "data6": "548176560",
        "data7": "36",
        "data8": "42804",
        "img": "img/65.png"
      }, {
        "data1": "4659908789095",
        "data2": "2138",
        "data3": "6835",
        "data4": "−78%",
        "data5": "1474",
        "data6": "280236880",
        "data7": "97",
        "data8": "142978",
        "img": "img/66.png"
      }, {
        "data1": "4651254736875",
        "data2": "1112",
        "data3": "6835",
        "data4": "−77%",
        "data5": "1574",
        "data6": "299988660",
        "data7": "102",
        "data8": "160548",
        "img": "img/67.png"
      }, {
        "data1": "4659873264565",
        "data2": "1674",
        "data3": "6469",
        "data4": "−77%",
        "data5": "1516",
        "data6": "699103400",
        "data7": "91",
        "data8": "137956",
        "img": "img/68.png"
      }, {
        "data1": "4659860076535",
        "data2": "650",
        "data3": "5725",
        "data4": "−78%",
        "data5": "1279",
        "data6": "243611130",
        "data7": "54",
        "data8": "69066",
        "img": "img/69.png"
      }, {
        "data1": "4651128675895",
        "data2": "942",
        "data3": "7270",
        "data4": "−80%",
        "data5": "1471",
        "data6": "678189840",
        "data7": "87",
        "data8": "127977",
        "img": "img/70.png"
      }, {
        "data1": "4659860574835",
        "data2": "141",
        "data3": "5360",
        "data4": "−77%",
        "data5": "1227",
        "data6": "565696080",
        "data7": "33",
        "data8": "40491",
        "img": "img/71.png"
      }, {
        "data1": "4650086327585",
        "data2": "46",
        "data3": "6090",
        "data4": "−80%",
        "data5": "1227",
        "data6": "565696080",
        "data7": "11",
        "data8": "13497",
        "img": "img/72.png"
      }, {
        "data1": "4659983264755",
        "data2": "24",
        "data3": "4120",
        "data4": "−79%",
        "data5": "857",
        "data6": "24587330",
        "data7": "8",
        "data8": "6856",
        "img": "img/73.png"
      }, {
        "data1": "4658832759485",
        "data2": "29",
        "data3": "3700 ",
        "data4": "−77%",
        "data5": "856",
        "data6": "60664720",
        "data7": "9",
        "data8": "7704",
        "img": "img/74.png"
      }, {
        "data1": "4658743453285",
        "data2": "150",
        "data3": "6835",
        "data4": "−77%",
        "data5": "1546",
        "data6": "294466620",
        "data7": "12",
        "data8": "18552",
        "img": "img/75.png"
      }, {
        "data1": "4659854387655",
        "data2": "4",
        "data3": "7357",
        "data4": "−79%",
        "data5": "1575",
        "data6": "725208750",
        "data7": "0",
        "data8": "0",
        "img": "img/76.png"
      }, {
        "data1": "4659986509875",
        "data2": "196",
        "data3": "6469 ",
        "data4": "−77%",
        "data5": "1517",
        "data6": "699519040",
        "data7": "55",
        "data8": "83435",
        "img": "img/77.png"
      }, {
        "data1": "4659087643455",
        "data2": "9",
        "data3": "1693",
        "data4": "−54%",
        "data5": "763",
        "data6": "14176540",
        "data7": "1",
        "data8": "763",
        "img": "img/78.png"
      }, {
        "data1": "4652217587975",
        "data2": "5",
        "data3": "5725 ",
        "data4": "−78%",
        "data5": "1240",
        "data6": "236331600",
        "data7": "0",
        "data8": "0",
        "img": "img/79.png"
      }, {
        "data1": "4659903486745",
        "data2": "40",
        "data3": "2400",
        "data4": "−76%",
        "data5": "567",
        "data6": "8635410",
        "data7": "11",
        "data8": "6237",
        "img": "img/80.png"
      }, {
        "data1": "4652398709875",
        "data2": "283",
        "data3": "6469",
        "data4": "−77%",
        "data5": "1517",
        "data6": "698502650",
        "data7": "33",
        "data8": "50061",
        "img": "img/81.png"
      }, {
        "data1": "4658943769585",
        "data2": "98",
        "data3": "6835",
        "data4": "−77%",
        "data5": "1574",
        "data6": "299248880",
        "data7": "25",
        "data8": "39350",
        "img": "img/82.png"
      }, {
        "data1": "4659832322235",
        "data2": "428",
        "data3": "5725 ",
        "data4": "−78%",
        "data5": "1279",
        "data6": "243611130",
        "data7": "34",
        "data8": "43486",
        "img": "img/83.png"
      }],
      list5: [{
        "data1": "4657843987543",
        "data2": "636",
        "data3": "615",
        "data4": "−70%",
        "data5": "185",
        "data6": "29505650",
        "data7": "121",
        "data8": "22385",
        "img": "img/84.png"
      }, {
        "data1": "4659687546753",
        "data2": "939",
        "data3": "990",
        "data4": "-81%",
        "data5": "189",
        "data6": "16212420",
        "data7": "78",
        "data8": "14742",
        "img": "img/85.png"
      }, {
        "data1": "4659867564533",
        "data2": "869",
        "data3": "1211",
        "data4": "−62%",
        "data5": "464",
        "data6": "9298560",
        "data7": "57",
        "data8": "26448",
        "img": "img/86.png"
      }, {
        "data1": "4658970685643",
        "data2": "5749",
        "data3": "1320 ",
        "data4": "−73%",
        "data5": "362",
        "data6": "16923500",
        "data7": "34",
        "data8": "12308",
        "img": "img/87.png"
      }, {
        "data1": "4658797564323",
        "data2": "1569",
        "data3": "1190 ",
        "data4": "−80%",
        "data5": "238",
        "data6": "20306160",
        "data7": "112",
        "data8": "26656",
        "img": "img/88.png"
      }, {
        "data1": "4658970867563",
        "data2": "130",
        "data3": "563",
        "data4": "−83%",
        "data5": "93",
        "data6": "14856750",
        "data7": "12",
        "data8": "1116",
        "img": "img/89.png"
      }, {
        "data1": "4657687654243",
        "data2": "25",
        "data3": "1320",
        "data4": "−72%",
        "data5": "371",
        "data6": "0",
        "data7": "0",
        "data8": "0",
        "img": "img/90.png"
      }, {
        "data1": "4658999756543",
        "data2": "125",
        "data3": "640",
        "data4": "−66%",
        "data5": "217",
        "data6": "4283580",
        "data7": "11",
        "data8": "2387",
        "img": "img/91.png"
      }],
      list6: [{
        "data1": "4659089786606",
        "data2": "894",
        "data3": "1200",
        "data4": "−85%",
        "data5": "183",
        "data6": "39244350",
        "data7": "103",
        "data8": "18849",
        "img": "img/92.png"
      }, {
        "data1": "4650987643276",
        "data2": "1011",
        "data3": "3500",
        "data4": "−80%",
        "data5": "702",
        "data6": "46198620",
        "data7": "46",
        "data8": "32292",
        "img": "img/93.png"
      }, {
        "data1": "4651123689086",
        "data2": "146",
        "data3": "950",
        "data4": "−79%",
        "data5": "201",
        "data6": "51717300",
        "data7": "11",
        "data8": "2211",
        "img": "img/94.png"
      }, {
        "data1": "4659089786606",
        "data2": "789",
        "data3": "4800",
        "data4": "−81%",
        "data5": "895",
        "data6": "14302100",
        "data7": "56",
        "data8": "50120",
        "img": "img/95.png"
      }, {
        "data1": "4650987643276",
        "data2": "45",
        "data3": "790",
        "data4": "−60%",
        "data5": "315",
        "data6": "321300",
        "data7": "1",
        "data8": "315",
        "img": "img/96.png"
      }, {
        "data1": "4654423689086",
        "data2": "1862",
        "data3": "5500 ",
        "data4": "−77%",
        "data5": "1254",
        "data6": "22158180",
        "data7": "23",
        "data8": "28842",
        "img": "img/97.png"
      }, {
        "data1": "4651256786606",
        "data2": "1887",
        "data3": "1200 ",
        "data4": "−87%",
        "data5": "160",
        "data6": "340800",
        "data7": "56",
        "data8": "8960",
        "img": "img/98.png"
      }, {
        "data1": "4650235643276",
        "data2": "11",
        "data3": "679 ",
        "data4": "−78%",
        "data5": "146",
        "data6": "804460",
        "data7": "0",
        "data8": "0",
        "img": "img/99.png"
      }, {
        "data1": "4651123689086",
        "data2": "765",
        "data3": "6900",
        "data4": "−67%",
        "data5": "2245",
        "data6": "13447550",
        "data7": "34",
        "data8": "76330",
        "img": "img/100.png"
      }, {
        "data1": "4659089786606",
        "data2": "144",
        "data3": "599",
        "data4": "−72%",
        "data5": "168",
        "data6": "21840",
        "data7": "15",
        "data8": "2520",
        "img": "img/101.png"
      }, {
        "data1": "4650987643276",
        "data2": "51",
        "data3": "329",
        "data4": "−67%",
        "data5": "109",
        "data6": "59950",
        "data7": "11",
        "data8": "1199",
        "img": "img/102.png"
      }, {
        "data1": "4651123665486",
        "data2": "50",
        "data3": "329",
        "data4": "−64%",
        "data5": "117",
        "data6": "64350",
        "data7": "3",
        "data8": "351",
        "img": "img/103.png"
      }, {
        "data1": "4659089458906",
        "data2": "75",
        "data3": "2966",
        "data4": "−82%",
        "data5": "545",
        "data6": "5450",
        "data7": "2",
        "data8": "1090",
        "img": "img/104.png"
      }, {
        "data1": "4650347643276",
        "data2": "56",
        "data3": "300 ",
        "data4": "−8%",
        "data5": "275",
        "data6": "5500",
        "data7": "9",
        "data8": "2475",
        "img": "img/105.png"
      }, {
        "data1": "4651123577086",
        "data2": "15",
        "data3": "1499 ",
        "data4": "−80%",
        "data5": "307",
        "data6": "0",
        "data7": "0",
        "data8": "0",
        "img": "img/106.png"
      }],
      list7: [{
        "data1": "4640964738658",
        "data2": "541",
        "data3": "1190 ",
        "data4": "−66%",
        "data5": "403",
        "data6": "5738720",
        "data7": "97",
        "data8": "39091",
        "img": "img/107.png"
      }, {
        "data1": "4640088675638",
        "data2": "465",
        "data3": "1999",
        "data4": "−32%",
        "data5": "1367",
        "data6": "23321020",
        "data7": "36",
        "data8": "49212",
        "img": "img/108.png"
      }, {
        "data1": "4649856009868",
        "data2": "302",
        "data3": "590",
        "data4": "−62%",
        "data5": "223",
        "data6": "115960",
        "data7": "24",
        "data8": "5352",
        "img": "img/109.png"
      }, {
        "data1": "4640064378568",
        "data2": "321",
        "data3": "1810",
        "data4": "−67%",
        "data5": "601",
        "data6": "2530210",
        "data7": "58",
        "data8": "34858",
        "img": "img/110.png"
      }, {
        "data1": "4648967432368",
        "data2": "621",
        "data3": "2799 ",
        "data4": "−72%",
        "data5": "792",
        "data6": "9765360",
        "data7": "46",
        "data8": "36432",
        "img": "img/111.png"
      }, {
        "data1": "4648756473528",
        "data2": "214",
        "data3": "1990",
        "data4": "−67%",
        "data5": "650",
        "data6": "2177500",
        "data7": "29",
        "data8": "18850",
        "img": "img/112.png"
      }, {
        "data1": "4649867584768",
        "data2": "563",
        "data3": "1990",
        "data4": "−67%",
        "data5": "650",
        "data6": "7299500",
        "data7": "89",
        "data8": "57850",
        "img": "img/113.png"
      }, {
        "data1": "4640976858568",
        "data2": "8",
        "data3": "1590",
        "data4": "−76%",
        "data5": "389",
        "data6": "3337620",
        "data7": "1",
        "data8": "389",
        "img": "img/114.png"
      }, {
        "data1": "4648765897658",
        "data2": "858",
        "data3": "1390",
        "data4": "−76%",
        "data5": "338",
        "data6": "2900040",
        "data7": "33",
        "data8": "11154",
        "img": "img/115.png"
      }, {
        "data1": "4642365748678",
        "data2": "7",
        "data3": "1980",
        "data4": "−88%",
        "data5": "228",
        "data6": "228000",
        "data7": "2",
        "data8": "456",
        "img": "img/116.png"
      }, {
        "data1": "4649876574588",
        "data2": "1",
        "data3": "1323",
        "data4": "−56%",
        "data5": "587",
        "data6": "10331200",
        "data7": "0",
        "data8": "0",
        "img": "img/117.png"
      }, {
        "data1": "4642386756458",
        "data2": "15",
        "data3": "1980",
        "data4": "−85%",
        "data5": "293",
        "data6": "293000",
        "data7": "1",
        "data8": "293",
        "img": "img/118.png"
      }],
      list8: [{
        "data1": "4659987687677",
        "data2": "689",
        "data3": "799",
        "data4": "−63%",
        "data5": "299",
        "data6": "765440",
        "data7": "98",
        "data8": "29302",
        "img": "img/119.png"
      }, {
        "data1": "4659908789097",
        "data2": "558",
        "data3": "329",
        "data4": "−47%",
        "data5": "175",
        "data6": "10697750",
        "data7": "102",
        "data8": "17850",
        "img": "img/120.png"
      }, {
        "data1": "4651254736877",
        "data2": "236",
        "data3": "399",
        "data4": "−58%",
        "data5": "166",
        "data6": "2083300",
        "data7": "43",
        "data8": "7138",
        "img": "img/121.png"
      }, {
        "data1": "4659873264567",
        "data2": "55",
        "data3": "1999",
        "data4": "−64%",
        "data5": "729",
        "data6": "1654830",
        "data7": "12",
        "data8": "8748",
        "img": "img/122.png"
      }, {
        "data1": "4659860076537",
        "data2": "332",
        "data3": "689",
        "data4": "−80%",
        "data5": "136",
        "data6": "529040",
        "data7": "34",
        "data8": "4624",
        "img": "img/123.png"
      }, {
        "data1": "4651128675897",
        "data2": "699",
        "data3": "799",
        "data4": "−68%",
        "data5": "254",
        "data6": "688340",
        "data7": "69",
        "data8": "17526",
        "img": "img/124.png"
      }, {
        "data1": "4659860574837",
        "data2": "568",
        "data3": "799",
        "data4": "−68%",
        "data5": "366",
        "data6": "358680",
        "data7": "47",
        "data8": "17202",
        "img": "img/125.png"
      }, {
        "data1": "4650086327587",
        "data2": "212",
        "data3": "1699",
        "data4": "−70%",
        "data5": "511",
        "data6": "3367490",
        "data7": "17",
        "data8": "8687",
        "img": "img/126.png"
      }, {
        "data1": "4659983264757",
        "data2": "254",
        "data3": "999",
        "data4": "−83%",
        "data5": "166",
        "data6": "144420",
        "data7": "23",
        "data8": "3818",
        "img": "img/127.png"
      }, {
        "data1": "4658832759487",
        "data2": "457",
        "data3": "439",
        "data4": "−47%",
        "data5": "234",
        "data6": "4563000",
        "data7": "79",
        "data8": "18486",
        "img": "img/128.png"
      }, {
        "data1": "4658743453287",
        "data2": "893",
        "data3": "799",
        "data4": "−82%",
        "data5": "146",
        "data6": "458440",
        "data7": "121",
        "data8": "17666",
        "img": "img/129.png"
      }, {
        "data1": "4659854387657",
        "data2": "303",
        "data3": "999 ",
        "data4": "−74%",
        "data5": "260",
        "data6": "1887600",
        "data7": "57",
        "data8": "14820",
        "img": "img/130.png"
      }, {
        "data1": "4659986509877",
        "data2": "114",
        "data3": "799 ",
        "data4": "−82%",
        "data5": "145",
        "data6": "440800",
        "data7": "11",
        "data8": "1595",
        "img": "img/131.png"
      }, {
        "data1": "4659903486747",
        "data2": "201",
        "data3": "890",
        "data4": "−83%",
        "data5": "149",
        "data6": "736060",
        "data7": "34",
        "data8": "5066",
        "img": "img/132.png"
      }, {
        "data1": "4652398709877",
        "data2": "172",
        "data3": "1999",
        "data4": "−64%",
        "data5": "729",
        "data6": "1676700",
        "data7": "9",
        "data8": "6561",
        "img": "img/133.png"
      }, {
        "data1": "4658943769587",
        "data2": "377",
        "data3": "799",
        "data4": "−69%",
        "data5": "244",
        "data6": "65880",
        "data7": "21",
        "data8": "5124",
        "img": "img/134.png"
      }],
      list9: [{
        "data1": "4660987456739",
        "data2": "600",
        "data3": "1 690",
        "data4": "−83%",
        "data5": "281",
        "data6": "141005800",
        "data7": "32",
        "data8": "8992",
        "img": "img/135.png"
      }, {
        "data1": "4660987653279",
        "data2": "689",
        "data3": "1 350",
        "data4": "−76%",
        "data5": "321",
        "data6": "24203400",
        "data7": "21",
        "data8": "6741",
        "img": "img/136.png"
      }, {
        "data1": "4661849309879",
        "data2": "571",
        "data3": "1 900",
        "data4": "−84%",
        "data5": "297",
        "data6": "2283930",
        "data7": "101",
        "data8": "29997",
        "img": "img/137.png"
      }, {
        "data1": "4660909864589",
        "data2": "986",
        "data3": "2 000",
        "data4": "−84%",
        "data5": "323",
        "data6": "28210820",
        "data7": "46",
        "data8": "14858",
        "img": "img/138.png"
      }, {
        "data1": "4668764332339",
        "data2": "105",
        "data3": "2 690 ",
        "data4": "−91%",
        "data5": "255",
        "data6": "257550",
        "data7": "4",
        "data8": "1020",
        "img": "img/139.png"
      }, {
        "data1": "4668976544389",
        "data2": "321",
        "data3": "2 000 ",
        "data4": "−89%",
        "data5": "221",
        "data6": "45800040",
        "data7": "22",
        "data8": "4862",
        "img": "img/140.png"
      }, {
        "data1": "4669874536279",
        "data2": "89",
        "data3": "2 999",
        "data4": "−87%",
        "data5": "397",
        "data6": "559770",
        "data7": "12",
        "data8": "4764",
        "img": "img/141.png"
      }, {
        "data1": "4668974232339",
        "data2": "12",
        "data3": "4 785",
        "data4": "−95%",
        "data5": "258",
        "data6": "5160",
        "data7": "1",
        "data8": "258",
        "img": "img/142.png"
      }, {
        "data1": "4665456789099",
        "data2": "324",
        "data3": "2 000",
        "data4": "−85%",
        "data5": "301",
        "data6": "6552770",
        "data7": "34",
        "data8": "10234",
        "img": "img/143.png"
      }, {
        "data1": "4660986473879",
        "data2": "46",
        "data3": "499 ",
        "data4": "−59%",
        "data5": "205",
        "data6": "12300",
        "data7": "2",
        "data8": "410",
        "img": "img/144.png"
      }, {
        "data1": "4667874896099",
        "data2": "112",
        "data3": "2 690",
        "data4": "−77%",
        "data5": "607",
        "data6": "2428000",
        "data7": "5",
        "data8": "3035",
        "img": "img/145.png"
      }, {
        "data1": "4669863256479",
        "data2": "67",
        "data3": "2 600",
        "data4": "−90%",
        "data5": "270",
        "data6": "294300",
        "data7": "2",
        "data8": "540",
        "img": "img/146.png"
      }, {
        "data1": "4667896342649",
        "data2": "98",
        "data3": "890 ",
        "data4": "−55%",
        "data5": "395",
        "data6": "667550",
        "data7": "1",
        "data8": "395",
        "img": "img/147.png"
      }, {
        "data1": "4668745327569",
        "data2": "12",
        "data3": "1 000",
        "data4": "−79%",
        "data5": "213",
        "data6": "23430",
        "data7": "0",
        "data8": "0",
        "img": "img/148.png"
      }, {
        "data1": "4669863275689",
        "data2": "4",
        "data3": "3 000",
        "data4": "−91%",
        "data5": "262",
        "data6": "23580",
        "data7": "1",
        "data8": "262",
        "img": "img/149.png"
      }, {
        "data1": "4663898765399",
        "data2": "678",
        "data3": "6 320 ",
        "data4": "−96%",
        "data5": "279",
        "data6": "2298960",
        "data7": "12",
        "data8": "3348",
        "img": "img/150.png"
      }],
      showEdit: false,
      totalNums: 0,
      currentPage: 1,
      pageCount: 10,
      name: ''
    }
  },
  filters : {
    num: (val) => { 
      val = '' + val // 转换成字符串
      let int = val
      int = int.split('').reverse().join('') // 翻转整数
      let temp = '' // 临时变量
      for (let i = 0; i < int.length; i++) { 
        temp += int[i]
        if ((i + 1) % 3 === 0 && i !== int.length - 1) { 
          temp += ',' // 每隔三个数字拼接一个逗号
        }
      }
      temp = temp.split('').reverse().join('') // 加完逗号之后翻转
      return temp // 返回
    }
  },
  created() {
    // this.loading = true
    // this.getSpus()
    // this.loading = false
    this.name = this.$route.meta.title
    console.log(this.name)

    switch(this.name) {
      case 'Kroft':
        this.list = this.list1
        break
      case 'SALRUS':
        this.list = this.list2
        break
       case 'Kastly':
        this.list = this.list3
        break
      case 'Uniqcute':
        this.list = this.list4
        break
      case 'TOPOCLEAN':
        this.list = this.list5
        break
      case 'MAKINO':
        this.list = this.list6
        break
      case 'Houseto':
        this.list = this.list7
        break
      case 'Romanky':
        this.list = this.list8
        break
      case 'SteelStar':
        this.list = this.list9
        break
    }
  },
  mounted() {
    var _this = this
    this.timeId = setInterval(function() {
      // _this.sday = dayjs().tz("Asia/Tokyo").format('DD.MM.YYYY HH:mm:ss')
      _this.sday = dayjs().tz("Europe/Moscow").format('DD.MM.YYYY HH:mm:ss')
    }, 1000)
  },
  beforeDestroy: function() {
    if (this.timeId) {
      clearInterval(this.timeId)
    }
  },
  methods: {
    async getSpus() {
      const page = this.currentPage - 1
      const count = this.pageCount
      const spus = await Spu.getSpus(page, count)
      this.tableData = spus.items
      this.totalNums = spus.total
      this.initImgSrcList()
    },
    initImgSrcList() {
      this.tableData.forEach(item => {
        if (!item.img) {
          return
        }
        this.imgSrcList.push(item.img)
      })
    },
    addSpu() {
      this.isCreate = true
      this.spuId = null
      this.showEdit = true
    },
    editClose() {
      this.showEdit = false
      this.getSpus()
    },
    handleEdit(val) {
      this.isCreate = false
      this.spuId = `${val.id}`
      this.showEdit = true
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0 30px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      height: 59px;
      line-height: 59px;
      color: #333333;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .count-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06);

    .count-item {
      flex: 1;
      padding: 0 20px;
      box-sizing: border-box;
      color: #333333;
      .title {
        font-weight: bold;
        font-size: 16px;
        height: 40px;
      }
      .num {
        margin-top: 20px;
      }

    }

  }

  .h-line {
    width: 1px;
    height:50px;
    background-color: #eee;
  }
}
</style>
