<template>
<div class="login">
    <div class="form-box" v-loading="loading">
      <div class="title"><h1></h1></div>
      <form class="login-form" @submit.prevent="throttleLogin()">
        <div class="form-item">
          <input type="text" v-model="form.username" autocomplete="off" placeholder="请填写用户名" />
        </div>
        <div class="form-item">
          <input type="password" v-model="form.password" autocomplete="off" placeholder="请填写用户登录密码" />
        </div>
        <button class="submit-btn" type="submit">登录</button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import Utils from '@/utils/utils'
import User from '@/model/user'

export default {
  name: 'login',
  data() {
    return {
      loading: false, // 加载动画
      wait: 2000, // 2000ms之内不能重复发起请求
      throttleLogin: null, // 节流登录
      form: {
        username: 'root',
        password: '123456'
      }
    }
  },

  methods: {
    async login() {
      const { username, password } = this.form
      try {
        this.loading = true
        await User.getToken(username, password)
        await this.getInformation()
        this.loading = false
        this.$router.push('/dashboard')
        this.$message.success('登录成功')
      } catch (e) {
        this.loading = false
        console.log(e)
      }
    },
    async getInformation() {
      try {
        // 尝试获取当前用户信息
        const user = await User.getPermissions()
        this.setUserAndState(user)
        this.setUserPermissions(user.permissions)
      } catch (e) {
        console.log(e)
      }
    },
    ...mapActions(['setUserAndState']),
    ...mapMutations({
      setUserPermissions: 'SET_USER_PERMISSIONS'
    })
  },

  created() {
    // 节流登录
    this.throttleLogin = Utils.throttle(this.login, this.wait)
  }
}
</script>

<style lang="scss" scoped>
.login {
  widows: 100%;
  height: 100%;
  background-color: #FFFFFF;

  .form-box {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 445px;

    .title {
      height: 37px;
      font-size: 30px;
      line-height: 37px;
      margin-bottom: 15%;

      h1 {
        box-sizing: border-box;
        color: #8c98ae;
      }
    }

    .login-form {
      width: 100%;

      .form-item {
        width: 100%;
        height: 40px;
        margin-bottom: 34px;

        input {
          width: 100%;
          height: 100%;
          background: transparent;
          color: #333333;
          font-size: 14px;
          padding-left: 30px;
          box-sizing: border-box;
          outline: none;
          border-radius: 2px;
          border: 1px solid #e9e9e9;
        }

        input:focus {
          border-color: #007fff;
        }
      }

      .submit-btn {
        width: 100%;
        height: 40px;
        color: #fff;
        font-size: 16px;
        background-color: #007fff;
        border: none;
        cursor: pointer;
        outline: none;
        border-radius: 2px;
      }
    }
  }
}
</style>
