import { get } from '@/utils/axios'

class SpecKey {
  static async getList() {
    const res = await get('v1/spec-key/list')
    return res
  }
}

export default SpecKey
