const categoryRouter = {
  route: null,
  name: 'Category',
  title: '分类管理1',
  type: 'folder',
  icon: 'el-icon-pie-chart',
  filePath: 'views/category/',
  order: null,
  inNav: true,
  children: [
    {
      title: '分类列表',
      type: 'view',
      name: 'categoryList',
      route: '/category/list',
      filePath: 'views/category/category-list.vue',
      inNav: true,
      icon: ''
    },
    {
      title: '子分类列表',
      type: 'tab',
      name: 'subCategoryList',
      route: '/sub-category/:id/list',
      filePath: 'views/category/sub-category-list.vue',
      inNav: false,
      icon: ''
    }
  ]
}

export default categoryRouter
