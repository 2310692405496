const specRouter = {
  route: null,
  name: 'spec',
  title: '规格管理',
  type: 'folder',
  icon: 'el-icon-pie-chart',
  filePath: 'views/spec/',
  order: null,
  inNav: true,
  children: [
    {
      title: '规格名列表',
      type: 'view',
      name: 'specKeyList',
      route: '/spec/key/list',
      filePath: 'views/spec/spec-key-list.vue',
      inNav: true,
      icon: ''
    },
    {
      title: '创建规格名',
      type: 'view',
      name: 'specKeyAdd',
      route: '/spec/key/add',
      filePath: 'views/spec/spec-key-add.vue',
      inNav: true,
      icon: ''
    },
    {
      title: '规格值详情',
      type: 'view',
      name: 'spceKeyValue',
      route: '/spec/key/value/:id',
      filePath: 'views/spec/spec-key-value.vue',
      icon: ''
    }
  ]
}

export default specRouter
