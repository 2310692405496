<template>
  <div class="container">
    CATEGORY-LIST
  </div>
</template>

<script>
export default {
}
</script>

<style>

</style>
