import { get, put } from '@/utils/axios'

class Sku {
  static async getSkus(page = 0, count = 10) {
    const res = await get('v1/sku/page', { page, count })
    return res
  }

  static async getSkuDetail(id) {
    const res = await get(`v1/sku/${id}/detail`)
    return res
  }

  static async editSku(id, data) {
    const res = await put(`v1/sku/${id}`, data)
    return res
  }
}

export default Sku
